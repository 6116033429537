import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { usePrevious } from "../../hooks/previous";
import { OverflowContainer } from "@circle/kip-components";

const InfiniteList = props => { // eslint-disable-line max-statements
    const ref = useRef();
    const wrapper = useRef();
    const prevCount = usePrevious(props.children.length);

    const [blocked, setBlocked] = useState(false);

    const hasMore = props.children.length > 0 &&
        (props.metadata.limit * props.metadata.page) < props.metadata.count &&
        !blocked;

    const Element = typeof props.element === "string" || props.element instanceof String ? props.element : props.element.type;
    const loader = props.loader();

    const isScrolledToEnd = () => {
        if(!wrapper.current || !ref.current) return false;

        const { scrollTop, scrollHeight, clientHeight } = wrapper.current;

        return scrollTop + clientHeight >= scrollHeight - 10;
    };

    const handleScroll = () => {
        if(isScrolledToEnd() && hasMore && !blocked) {
            setBlocked(true);
            props.onFetch();
        }
    };

    useEffect(() => {
        const currentWrapper = wrapper.current;

        currentWrapper.addEventListener("scroll", handleScroll);

        return () => {
            currentWrapper.removeEventListener("scroll", handleScroll);
        };
    }, [blocked, hasMore]);

    useEffect(() => {
        if(props.children.length > prevCount)
            setBlocked(false);
    }, [props.children.length, prevCount]);

    return (
        <OverflowContainer className={props.className} ref={wrapper}>
            <Element>
                {props.children.length > 0 && props.children}
                {props.children.length <= 0 && props.placeholder}
                {blocked && <loader.type>{loader.props.children}</loader.type>}
                {hasMore && !blocked && <loader.type ref={ref}>{loader.props.children}</loader.type>}
            </Element>
        </OverflowContainer>
    );
};

InfiniteList.defaultProps = {
    className:   "",
    onFetch:     x => x,
    element:     "div",
    loader:      () => <div>Loading...</div>,
    placeholder: <div></div>,
    metadata:    {
        limit: 10,
        count: -1,
        page:  1
    }
};

InfiniteList.propTypes = {
    children:    PropTypes.node,
    metadata:    PropTypes.object,
    loader:      PropTypes.func,
    element:     PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onFetch:     PropTypes.func,
    className:   PropTypes.string,
    placeholder: PropTypes.object
};

export { InfiniteList };
