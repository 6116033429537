import { useBranch, useTranslation } from "@circle/gestalt-app";
import html2canvas from "html2canvas";
import { DropDownButton, Icon } from "@circle/kip-components";

const Export = () => {
    const { translate }    = useTranslation();
    const { queryOptions } = useBranch({
        queryOptions: ["queryOptions"]
    });

    const onClick = () => {
        const plantId = queryOptions.selectedPlant.id;
        const from    = queryOptions.calendar.from.getTime() / 1000;
        const until   = queryOptions.calendar.until.getTime() / 1000;

        const link = `${window.config.backendUrl}/produced_parts/download/csv?plantId=${plantId}&timestamp=[${from},${until}]`;

        window.open(link, "_blank").focus();

        return null;
    };

    const options = [{
        value: "produced_parts",
        label: <><Icon className="mr-2" _icon="List"/>{ translate("csv.produced.parts") }</>
    }];

    return (
        <div className="export-block flex-row-reverse relative flex">
            <DropDownButton
                className="export-dropdown"
                buttonProps={{
                    _variant: "icon"
                }}
                options={options}
                onClick={onClick}
                closeOnOptionClick
            >
                <Icon _icon="Export"/>
            </DropDownButton>
        </div>
    );
};

export { Export };
