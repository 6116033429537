import PropTypes from "prop-types";
import { numberFormatter } from "../../../../helper/numberFormatter";

import styles from "./performanceList.module.scss";

const PerformanceList = props => {
    return (
        <ol className={styles.customList}>
            { props.data?.map((elem, index) =>
                <li key={index}>
                    {elem.name}
                    <div className={styles.subHeader}>
                        <div className={styles.subHeaderBlock}>
                            <span>{elem.portion}%</span>
                            <small>{props.translate("overview.proportion")}</small>
                        </div>
                        <div className={styles.subHeaderBlock}>
                            <span>{numberFormatter(elem.produced, 99999999)}</span>
                            <small>{props.translate("dashboard.widgets.produced.header")}</small>
                        </div>
                    </div>
                </li>
            )}
        </ol>
    );
};

PerformanceList.propTypes = {
    translate: PropTypes.func,
    data:      PropTypes.arrayOf(PropTypes.object)
};

export { PerformanceList };
