import { useTranslation, useTrigger, useBranch } from "@circle/gestalt-app";
import { Button, Icon } from "@circle/kip-components";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { resolveClassNames } from "palstek";
import { numberFormatter } from "../../helper/numberFormatter";
import { BusyIndicator } from "../BusyIndicator";

import styles from "./overviewItem.module.scss";

const OverviewItem = props => { // eslint-disable-line complexity
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();
    const { options }   = useBranch({
        options: ["queryOptions"]
    });

    const onClick = e => {
        e.preventDefault();
        e.stopPropagation();

        props.onPlusClick();
    };

    useEffect(() => {
        trigger("fetchOverviewItem", { plantId: props.item.id });

        const interval = setInterval(() => trigger("fetchOverviewItem", { plantId: props.item.id }), 30000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        trigger("fetchOverviewItem", { plantId: props.item.id, reset: true });
    }, [options.calendar]);

    return (
        <Link className={styles.item} to={`/overview/${props.item.id}${window.location.search}`} >
            <div className={styles.itemContent}>
                <div className={styles.identifier}>
                    <img className={styles.logo} src={props.item.image} loading="lazy" alt="Manufacturer Logo" />
                    <div className={styles.nameContainer}>
                        <b className={styles.name}>{props.item.name}</b>
                        <span>{translate(props.item.location)}</span>
                    </div>
                </div>
                <div className={resolveClassNames(styles.contentContainer, !["custom", "yesterday"].includes(options.range) && styles.isFull)}>
                    {
                        !props.item.hasOwnProperty("availability") && // eslint-disable-line no-prototype-builtins
                        <div className={styles.loadingContainer}>
                            <BusyIndicator />
                        </div>
                    }
                    {
                        props.item.hasOwnProperty("availability") && // eslint-disable-line no-prototype-builtins
                        <>
                            {
                                !["custom", "yesterday"].includes(options.range) &&
                                <span className={styles.label}>{translate("shifts.start")}</span>
                            }
                            <span className={styles.label}>{translate("overview.tile.technical.availability")}</span>
                            <span className={styles.label}>{translate("overview.tile.availability")}</span>
                            <span className={styles.label}>{translate("overview.performance")}</span>
                            <span className={styles.label}>{translate("overview.duration")}</span>
                            <span className={styles.label}>{translate("disruption.time")}</span>
                            {
                                !["custom", "yesterday"].includes(options.range) &&
                                <div className={styles.inlineContainer}>
                                    <span className={styles.value}>
                                        {
                                            props.item.shift &&
                                            new Date(props.item.shift.startTime * 1000).toLocaleTimeString([], { timeZone: "utc", hour: "2-digit", minute: "2-digit" })
                                        }
                                        {
                                            !props.item.shift &&
                                            "-"
                                        }
                                    </span>
                                    <span>{translate("overview.hours")}</span>
                                </div>
                            }
                            <span className={styles.value}>{props.item.runtime > 0 ? `${props.item.techAvailability}%` : "-"}</span>
                            <span className={styles.value}>{props.item.runtime > 0 ? `${props.item.availability}%` : "-"}</span>
                            <div>
                                <div className={resolveClassNames(styles.inlineContainer, styles.fullWidth)}>
                                    <span className={styles.value}>
                                        {
                                            props.item.performance &&
                                            `${props.item.runtime > 0 ? numberFormatter(props.item.performance.absolute.current, 999999) : "-"}/${props.item.performance.absolute.target > 0 ? numberFormatter(props.item.performance.absolute.target, 999999) : "-"}`
                                        }
                                        {
                                            !props.item.performance &&
                                            "-"
                                        }
                                    </span>
                                    <span>{translate("tile.unit")}</span>
                                </div>
                                <div className={styles.inlineContainer}>
                                    <b>
                                        {
                                            props.item.performance &&
                                            `ø ${props.item.runtime > 0 ? numberFormatter(props.item.performance.perMinute.current, 999999, true) : "-"}/${props.item.performance.perMinute.target > 0 ? numberFormatter(props.item.performance.perMinute.target, 99999, true) : "-"}`
                                        }
                                        {
                                            !props.item.performance &&
                                            "-"
                                        }
                                    </b>
                                    <span className={styles.underText}>{translate("overview.tile.unit.min")}</span>
                                </div>
                            </div>
                            <div className={styles.inlineContainer}>
                                <span className={styles.value}>{props.item.runtime}</span>
                                <span>{translate("overview.minutes")}</span>
                            </div>
                            <div className={styles.timeContainer}>
                                <div>
                                    <div className={styles.inlineContainer}>
                                        <span className={styles.value}>{props.item.interruptionTimes?.technical}</span>
                                        <span>{translate("overview.minutes")}</span>
                                    </div>
                                    <div>
                                        <span className={styles.underText}>{translate("overview.technical.time")}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className={styles.inlineContainer}>
                                        <span className={styles.value}>{props.item.interruptionTimes?.organizational}</span>
                                        <span>{translate("overview.minutes")}</span>
                                    </div>
                                    <div>
                                        <span className={styles.underText}>{translate("overview.organizational.time")}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
                <Button className={styles.eventButton} onClick={onClick}>
                    <Icon className="mr-2" _icon="Add" />
                    {translate("overview.tile.event")}
                </Button>
            </div>
        </Link>
    );
};

OverviewItem.propTypes = {
    item:        PropTypes.object,
    onClick:     PropTypes.func.isRequired,
    onPlusClick: PropTypes.func.isRequired,
    locale:      PropTypes.string
};

export { OverviewItem };
