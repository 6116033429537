import { useState, useEffect } from "react";
import { OverviewItem } from "./OverviewItem";
import { EventForm } from "../EventForm";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { useQueryString } from "../../hooks/querystring";
import { useQuery } from "../../hooks/query";
import { useNavigate } from "react-router-dom";
import { Modal, OverflowContainer } from "@circle/kip-components";
import { OverviewFilter } from "./overviewFilter/OverviewFilter";
import { PlantOverviewHeader } from "../generic/plantOverviewHeader/PlantOverviewHeader";

import styles from "./overview.module.scss";

const Overview = () => {
    const { trigger }                    = useTrigger();
    const { translate, currentLanguage } = useTranslation();
    const [modal, setModal]              = useState(false);

    const { options, licensedPlants, items, selectedPlant } = useBranch({
        options:        ["queryOptions"],
        licensedPlants: ["sortedPlants"],
        items:          ["overview"],
        selectedPlant:  ["selectedPlant"]
    });

    const [filter] = useQueryString({
        options:  options,
        plants:   licensedPlants,
        optional: {
            range:    x => (x ?? "").replace("undefined", "") || options.range || "last7Days",
            order:    () => options.order || "asc",
            orderKey: () => options.orderKey || "name"
        }
    });

    const navigate = useNavigate();

    const onClick = selectedId => {
        setModal(true);

        trigger("setPlant", selectedId);
    };

    const onClose = () => {
        setModal(false);

        return navigate(".");
    };

    const onFilterChange = val => {
        trigger("applyOption", "orderKey", val.orderKey);
        trigger("applyOption", "order", val.order);
    };

    const onSubmit = event => {
        trigger("createEvent", event);

        onClose();
    };

    const [init] = useQuery({
        options: filter,
        keys:    ["range", "startTime", "order", "orderKey"]
    });

    useEffect(() => {
        trigger("resetSelections");

        return () => trigger("clearMemory");
    }, []);

    return (
        <>
            {
                init &&
                <OverflowContainer className={styles.overflow} keepY={true}>
                    <div className={styles.overview}>
                        <div className={styles.headline}>
                            <PlantOverviewHeader
                                title={translate("overview.content.header") }
                                options={options}
                                currentLanguage={currentLanguage}
                                translate={translate}
                            />
                            <div className={styles.filterContainer}>
                                <div>
                                    <span className={styles.sort}>{translate("overview.sort")}</span>
                                </div>
                                <OverviewFilter
                                    orderKey={options.orderKey}
                                    order={options.order}
                                    onChange={onFilterChange}
                                />
                            </div>
                        </div>
                        <div className={styles.mainViewContainer}>
                            {
                                licensedPlants.map(plant => (
                                    <OverviewItem
                                        key={plant.id}
                                        item={{
                                            ...items.find(x => x.plantId === plant.id),
                                            image:    plant.manufacturer.image || plant.image || "/images/Bitmap.png",
                                            name:     translate(plant.name),
                                            location: plant.location,
                                            id:       plant.id
                                        }}
                                        onPlusClick={() => onClick(plant.id)}
                                        onClick={() => trigger("onOverviewPlantSelect", plant.id)}
                                    />
                                ))
                            }
                        </div>
                        {
                            selectedPlant &&
                        <Modal
                            title={translate("title.record.event")}
                            isOpen={modal}
                            isCloseable={false}
                            onClose={() => setModal(false)}
                            className="event-modal"
                        >
                            <div id="event-form" className="flex-column">
                                <EventForm
                                    isEditable={true}
                                    onClose={onClose}
                                    selectedPlant={selectedPlant}
                                    onSubmit={onSubmit}
                                    plantId={selectedPlant.id}
                                />
                            </div>
                        </Modal>
                        }
                    </div>
                </OverflowContainer>
            }
        </>
    );
};

export { Overview };
