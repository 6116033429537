import { resolveClassNames } from "palstek";
import PropTypes from "prop-types";

import styles from "./tooltip.module.scss";

const Tooltip = ({ active, label, payload, formatter, translate, isNoUnit }) => {
    const formatValue = ele => { // eslint-disable-line complexity
        if(ele.dataKey === "organizational" ||
            ele.dataKey === "technical" ||
            (ele.dataKey === "amount" && !isNoUnit) ||
            ele.dataKey === "error" ||
            ele.dataKey === "maintenance" ||
            ele.dataKey === "setup" ||
            ele.dataKey === "notReady" ||
            ele.dataKey === "blocked" ||
            ele.dataKey === "standstill"
        )
            return `${ele.value} ${translate("overview.minutes")}`;

        if(ele.dataKey === "cycleTime") return `${ele.value} ${translate("overview.seconds.short")}`;

        return formatter(ele.value);
    };

    const getValue = ele => {
        if(ele.name === "missing") return formatValue({
            ...ele,
            value: ele.payload.target
        });
        return ele.value ? formatValue(ele) : 0;
    };
    const filteredData = payload?.filter(elem => elem.value !== 0).reverse();

    return (
        <>
            {
                active && payload && filteredData.length ?
                    <div className="custom-tooltip">
                        <p className="tooltip-label">{`${label}`}</p>
                        {
                            filteredData?.map((ele, key) => (
                                <p key={key} className="tooltip-block">
                                    <span className={resolveClassNames(styles[ele.name], styles.tooltipIcon)}>&#11044;</span>
                                    <span className="tooltip-icon"> {translate(`type.${ele.name}`)}: </span>
                                    { getValue(ele) }
                                </p>
                            ))
                        }
                    </div> :
                    <></>}
        </>
    );
};

Tooltip.defaultProps = {
    formatter: x => x
};

Tooltip.propTypes = {
    active:    PropTypes.bool,
    payload:   PropTypes.arrayOf(PropTypes.object),
    formatter: PropTypes.func,
    translate: PropTypes.func,
    isNoUnit:  PropTypes.bool,
    label:     PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export { Tooltip };
