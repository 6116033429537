import PropTypes from "prop-types";

const SquareCustomizedDot = props => {
    const { cx, cy } = props;
    if (props.value === null) return null;
    
    return (
        <svg x={cx && cx - 5} y={cy && cy - 5} width={10} height={10} viewBox="0 0 16 16" fill="#f5f5f5" stroke={props.color} strokeWidth="8">
            <rect width="16" height="16" rx="4" stroke={props.color} strokeWidth="9" fill="#f5f5f5" />
        </svg>
    );
};

SquareCustomizedDot.propTypes = {
    cx:    PropTypes.number,
    cy:    PropTypes.number,
    color: PropTypes.string,
    value: PropTypes.number
};

export { SquareCustomizedDot };
