import PropTypes from "prop-types";

import styles from "./radioButton.module.scss";
import { resolveClassNames } from "palstek";

const RadioButton = props => {
    const onChange = e => {
        e.preventDefault();
        props.onChange(e);
    };

    return (
        <div className={styles.container}>
            <label className={resolveClassNames(!props.disabled ? "clickable" : styles.radioDisable, styles.radioLabel)}>
                <input disabled={props.disabled} type="radio" className={styles.radioInput} value={props.value} checked={props.checked} onChange={onChange} name={props.name} />
                <span className={styles.radioIndicator} />
                {props.children}
            </label>
        </div>
    );
};

RadioButton.propTypes = {
    disabled: PropTypes.bool,
    checked:  PropTypes.bool,
    onChange: PropTypes.func,
    name:     PropTypes.string,
    value:    PropTypes.string,
    children: PropTypes.node
};

RadioButton.defaultProps = {
    checked: false
};

export { RadioButton };
