import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Icon } from "@circle/kip-components";
import { useTrigger } from "@circle/gestalt-app";

import styles from "./favoriteCard.module.scss";

const FavoriteCard = props => {
    const { trigger }        = useTrigger();

    const onObservationSelect = e => {
        e.stopPropagation();
        e.preventDefault();

        if(props.item.type === "cluster") {
            const editFavorite =  {
                clusterId:      props.item.id,
                plantId:        props.item.plantId,
                plantElementId: null
            };

            trigger("editFavorite", editFavorite);
            return;
        }
        const editFavorite =  {
            clusterId:      null,
            plantId:        props.item.plantId,
            plantElementId: props.item.id
        };

        trigger("editFavoriteElement", editFavorite);
        return;
    };

    return (
        props.item?.type === "cluster" ?
            <Link className={styles.cardContainer} to={`/favorite/${props.item.plantId}/${props.item.id}/null/${window.location.search}`}>
                <div className={styles.container}>
                    <div className={styles.title}>
                        <span className={styles.mainTitle}>{props.translate(props.item.name)}</span>
                    </div>
                    <Button _appearance="primary" _variant="icon" onClick={onObservationSelect}>
                        <Icon _icon="StarSolid" />
                    </Button>
                </div>
            </Link> :
            <Link className={styles.cardContainer} to={`/favorite/${props.item.plantId}/null/${props.item.id}${window.location.search}`}>
                <div className={styles.container}>
                    <div className={styles.title}>
                        <span className={styles.mainTitle}>{props.translate(props.item.name)}</span>
                        {/* <span className={styles.description}>{props.item.updatedAt}</span> */}
                    </div>
                    <Button _appearance="primary" _variant="icon" onClick={onObservationSelect}>
                        <Icon _icon="StarSolid" />
                    </Button>
                </div>
            </Link>
    );
};

FavoriteCard.propTypes = {
    item:      PropTypes.object,
    translate: PropTypes.func
};

export { FavoriteCard };
