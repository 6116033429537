import { Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, LineChart } from "recharts";
import PropTypes from "prop-types";
import { useTranslation } from "@circle/gestalt-app";

import { SquareCustomizedDot } from "../../../../generic/squareCustomizedDot";
import { CircleCustomizedDot } from "../../../../generic/circleCustomizedDot/CircleCustomizedDot";
import { renderCustomizedLabel } from "../../../../../helper/customizeLabel";
import { Tooltip as CustomTooltip } from "../../../../generic/Tooltip";
import { BusyIndicator } from "../../../../BusyIndicator";

import styles from "./availabilityGraph.module.scss";

const AvailabilityGraph = props => {
    const { translate } = useTranslation();
    const isContentAvailable = props.data.length >= 1;

    return (
        <div className={styles.mainContainer} style={{ maxHeight: 200 }}>
            {
                props.isLoading &&
                <>
                    <div className={styles.headerContainer}>
                        <span className={styles.headerTitle}>
                            <b>{translate("detail.cluster.graph.availability")}</b>
                        </span>
                    </div>
                    <BusyIndicator />
                </>
            }
            {
                !props.isLoading &&
                <>
                    <div className={styles.headerContainer}>
                        <span className={styles.headerTitle}>
                            <b>{translate("detail.cluster.graph.availability")}</b>
                        </span>
                    </div>
                    <div className={styles.graphContainer}>
                        {
                            isContentAvailable &&
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart
                                    width={500}
                                    height={100}
                                    data={props.data}
                                    margin={{
                                        top:    25,
                                        right:  20,
                                        left:   -20,
                                        bottom: 30
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false}/>
                                    <XAxis dataKey="date" tick={{ fontSize: 8 }} />
                                    <YAxis axisLine={{ stroke: "transparent" }} domain={[0, 100]} tick={{ fontSize: 8 }} />
                                    <Tooltip content={<CustomTooltip translate={translate} isNoUnit={true} />}/>
                                    <Line
                                        type="monotone"
                                        dataKey="techAvailability"
                                        stroke="#7d7d7d"
                                        strokeWidth={3}
                                        dot={false}
                                    />
                                    <Line
                                        type="monotone"
                                        dataKey="availability"
                                        stroke="#acacac"
                                        strokeWidth={3}
                                        dot={false}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        }
                        {
                            !isContentAvailable &&
                            <div className={styles.noContent}>
                                <span>{translate("overview.no.content")}</span>
                            </div>
                        }
                    </div>
                </>
            }
        </div>
    );
};

AvailabilityGraph.propTypes = {
    data:      PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool
};

export { AvailabilityGraph };
