import PropTypes from "prop-types";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { BusyIndicator } from "../../BusyIndicator";
import { Tooltip as CustomTooltip } from "../../generic/Tooltip";

import styles from "./combinedGraphWidget.module.scss";

const CombinedGraphWidget = props => {
    const isContentAvailable = props.data.length >= 1;

    const data = isContentAvailable ? props.data.map(x => ({
        ...x,
        progress: x.produced,
        missing:  x.target - x.produced < 0 ? 0 : x.target - x.produced,
        target:   x.target
    })) : [];

    return (
        <div className={styles.mainContainer} style={{ maxHeight: props.graphHeight ?? 225 }}>
            <div className={styles.headerContainer}>
                <span className={styles.headerTitle}><b>{props.mainHeaderTitle}</b>{" "}{props.secondaryHeaderTitle}</span>
            </div>

            <div className={styles.graphContainer}>
                { props.isLoading && <BusyIndicator /> }
                { (!props.isLoading && isContentAvailable) &&
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top:    20,
                            right:  10,
                            left:   -10,
                            bottom: 20
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" tick={{ fontSize: 12 }} />
                        <YAxis domain={[0, 100]} tick={{ fontSize: 12 }}/>
                        <Tooltip content={<CustomTooltip translate={props.translate} />} />
                        <Bar dataKey="progress" stackId="a" barSize={40} fill="#7DA7D9" />
                        <Bar dataKey="missing" stackId="a" barSize={40} fill="#D7D7D7" />
                    </BarChart>
                </ResponsiveContainer>
                }
                {
                    (!props.isLoading && !isContentAvailable) &&
                    <div><span>{props.translate("overview.no.content")}</span></div>
                }
            </div>
        </div>
    );
};

CombinedGraphWidget.propTypes = {
    graphHeight:          PropTypes.number,
    mainHeaderTitle:      PropTypes.string,
    secondaryHeaderTitle: PropTypes.string,
    data:                 PropTypes.arrayOf(PropTypes.object),
    isLoading:            PropTypes.bool,
    translate:            PropTypes.func
};

CombinedGraphWidget.defaultProps = {
    data: []
};

export { CombinedGraphWidget };
