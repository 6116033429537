import { useState, useEffect } from "react";
import { Button, SwitchButton, Modal } from "@circle/kip-components";
import { useTranslation, useBranch, useTrigger } from "@circle/gestalt-app";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { numberFormatter } from "../../../helper/numberFormatter";
import { InfoBox } from "../../generic/infoBox/InfoBox";
import { EventForm } from "../../EventForm";

import { BusyIndicator } from "../../BusyIndicator";

import styles from "./eventsContent.module.scss";
import { resolveClassNames } from "palstek";

const EventsContent = props => { // eslint-disable-line complexity
    const { trigger }         = useTrigger();
    const { translate }       = useTranslation();
    const [filter, setFilter] = useState("frequency");
    const [modal, setModal]   = useState(false);

    const { plant, content, contentGroups, options, isLoading, events } = useBranch({
        plant:         ["selectedPlant"],
        content:       ["eventsContent"],
        contentGroups: ["eventsOverview", "content"],
        options:       ["queryOptions"],
        isLoading:     ["loadingViews", "eventsOverview"],
        events:        ["events"]
    });

    const onSelect = e => {
        setFilter(e.target.value);
    };

    const onEventCreate = e => {
        e.preventDefault();
        e.stopPropagation();
        setModal(!modal);
    };

    const onClose = () => {
        setModal(!modal);
    };

    const onSubmit = event => {
        trigger("createEvent", event, { plantId: props.plantId });

        onClose();
    };

    useEffect(() => {
        trigger("fetchTopEventGroups", { plantId: props.plantId, orderKey: filter === "frequency" ? "count" : "duration" });
    }, [options, filter, events]);

    useEffect(() => {
        trigger("fetchEventsContentOverview", { plantId: props.plantId });
    }, [options]);

    useEffect(() => {
        trigger("fetchEventsContentOverview", { plantId: props.plantId });
        trigger("fetchTopEventGroups", { plantId: props.plantId, orderKey: filter === "frequency" ? "count" : "duration" });

        const interval = setInterval(() => {
            trigger("fetchEventsContentOverview", { plantId: props.plantId, polling: true });
            trigger("fetchTopEventGroups", { plantId: props.plantId, orderKey: filter === "frequency" ? "count" : "duration", polling: true });
        }, 30000);

        return () => clearInterval(interval);
    }, []);

    const isContentAvailable = (content?.overview?.count > 0) || false;

    return (
        <>
            { isLoading &&
                <div className={styles.item}>
                    <InfoBox
                        isHeader={true}
                        title={translate("dashboard.widgets.events")}
                        className={"isSmallWidth"}
                        showChevron={isContentAvailable}
                    >
                        <BusyIndicator />
                    </InfoBox>
                </div>
            }
            { (!isLoading && isContentAvailable) &&
                <Link className={styles.item} to={isContentAvailable ? `/events/${props.plantId}` : null}>
                    <InfoBox
                        isHeader={true}
                        title={translate("dashboard.widgets.events")}
                        className={"isSmallWidth"}
                        showChevron={isContentAvailable}
                    >
                        <>
                            <div className={styles.header}>
                                <div>
                                    <div className={styles.statisticContainer}>
                                        <span className={styles.statisticTitle}>{ numberFormatter(content.overview.count, 9999999) }</span>
                                    </div>
                                    <small>{translate("dashboard.widgets.events")}</small>
                                </div>
                            </div>
                            <div className={styles.subHeader}>
                                <div className={styles.subHeaderBlock}>
                                    <span>{numberFormatter(content.overview.maintenance, 999999)}</span>
                                    <small>{translate("maintenance.headline")}</small>
                                </div>
                                <div className={styles.subHeaderBlock}>
                                    <span>{numberFormatter(content.overview.setup, 999999)}</span>
                                    <small>{translate("setup.headline")}</small>
                                </div>
                                <div className={styles.subHeaderBlock}>
                                    <span>{numberFormatter(content.overview.other, 999999)}</span>
                                    <small>{translate("overview.various")}</small>
                                </div>
                            </div>

                            <div className={styles.filterContainer} onClick={e => e.stopPropagation()}>
                                <SwitchButton
                                    styles={{ zIndex: 99999 }}
                                    onChange={val => onSelect(val)}
                                    options={[
                                        {
                                            value:   "frequency",
                                            checked: filter === "frequency",
                                            label:   translate("overview.filter.frequency")
                                        },
                                        {
                                            value:   "duration",
                                            checked: filter === "duration",
                                            label:   translate("overview.filter.duration")
                                        }]}
                                />
                            </div>
                            <div className={styles.horizontalCenter}>
                                <span className={styles.eventHeadline}>{ translate("overview.top5.events").replace("$1", contentGroups?.items?.length).replace("$2", contentGroups?.totalItems) }</span>
                            </div>
                            <div className={styles.tableHeader}>
                                <div className={styles.filter}>
                                    {
                                        filter === "frequency" &&
                                        <small>{ translate("dashboard.widgets.produced.amount") }</small>
                                    }
                                    {
                                        filter === "duration" &&
                                        <small>{translate("overview.filter.duration")}</small>
                                    }
                                </div>
                                <span className={styles.colPadding}>{translate("element.name.header")}</span>
                            </div>
                            <div className={styles.contentContainer}>
                                {
                                    (contentGroups?.items || []).map((x, index) =>
                                        <div className={styles.itemContainer} key={index}>
                                            <div className={styles.filter}>
                                                {
                                                    filter === "frequency" &&
                                                    <small className={styles.itemCount}>{numberFormatter(x.count, 9999999)}</small>
                                                }
                                                {
                                                    filter === "duration" &&
                                                    <small className={styles.itemCount}>{numberFormatter(x.duration, 999999)} <small className={styles.subItem}>{translate("overview.minutes")}</small></small>
                                                }
                                            </div>
                                            <small className={resolveClassNames(styles.colPadding, styles.item, styles.clamped)}>{translate(x.name)}</small>
                                        </div>
                                    )}
                            </div>
                        </>
                        <Button className={styles.eventButton} onClick={onEventCreate}>{translate("overview.new.event")}</Button>
                    </InfoBox>
                </Link>
            }
            { (!isContentAvailable && !isLoading) &&
            <div className={styles.item}>
                <InfoBox
                    isHeader={true}
                    isPositiveIncrease={true} title={translate("dashboard.widgets.events")}
                    className={"isSmallWidth"}
                    showChevron={isContentAvailable}
                >
                    <div className={styles.noContent}>
                        <span>{translate("overview.no.content")}</span>
                    </div>
                    <Button className={styles.eventButton} onClick={onEventCreate}>{translate("overview.new.event")}</Button>
                </InfoBox>
            </div>
            }

            { plant &&
                <Modal
                    title={translate("title.record.event")}
                    isOpen={modal}
                    isCloseable={false}
                    onClose={onClose}
                    className="event-modal"
                >
                    <div id="event-form" className="flex-column">
                        <EventForm
                            isEditable={true}
                            onClose={onClose}
                            selectedPlant={plant}
                            onSubmit={onSubmit}
                            plantId={plant.id}
                        />
                    </div>
                </Modal>
            }
        </>
    );
};

EventsContent.propTypes = {
    plantId: PropTypes.string
};

export { EventsContent };
