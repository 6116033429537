import { NavLink } from "react-router-dom";
import { Fragment } from "react";
import { Icon } from "@circle/kip-components";
import { useTrigger } from "@circle/gestalt-app";
import PropTypes from "prop-types";
import { BreadcrumbItem } from "./BreadcrumbItem";

import styles from "./breadcrumb.module.scss";

const Breadcrumb = props => {
    const { trigger } = useTrigger();

    const onClick = () => {
        trigger("applyPlant", {});
    };
    const onTrigger = (path, value) => {
        trigger(path, value);
    };

    return (
        <div className={styles.container}>
            <NavLink onClick={onClick} to={props.homeLink} rel="noopener noreferrer">
                <Icon className={styles.home} _icon="Home"/>
            </NavLink>
            {
                props.items ? props.items?.map((item, key) => (
                    <Fragment key={key}>
                        <Icon className={styles.chevron} _icon="ChevronRight"/>
                        <BreadcrumbItem
                            clickable={key !== props.items?.length - 1 }
                            onApplyIndex={y => y}
                            onTrigger={onTrigger} index={item.index}
                            value={item.value}
                            path={item.path}
                            trigger={item.trigger}
                            onClick={item.onClick}
                            className={styles.link}
                        />
                    </Fragment>
                )) : <></>
            }
        </div>
    );
};

Breadcrumb.propTypes = {
    items:    PropTypes.arrayOf(PropTypes.object),
    homeLink: PropTypes.string
};

Breadcrumb.defaultProps = {
    homeLink: "/"
};

export { Breadcrumb };
