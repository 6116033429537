import PropTypes from "prop-types";
import { resolveClassNames } from "palstek";

import styles from "./horizontalBarGraph.module.scss";
import { numberFormatter } from "../../../helper/numberFormatter";

const HorizontalBarGraph = props => {
    const performance = props.data.performance || 0;

    return (
        <div className={ styles.progressLine}>
            <div className={ styles.container}>
                <div className={resolveClassNames(styles.progress, styles.producedParts)} style={{ width: `${performance <= 100 ? performance : 100}%` }}></div>
                <div className={resolveClassNames(styles.progress, styles.producedPartsTarget)} style={{ width: `${100 - (performance <= 100 ? performance : 100)}%` }}></div>
                {
                    performance > 0 && props.data.target > 0 &&
                    <span className={styles.performance}>{`${numberFormatter(performance, 999999, true) }%`}</span>
                }
            </div>
        </div>
    );
};

HorizontalBarGraph.propTypes = {
    data:         PropTypes.object,
    isComparison: PropTypes.bool
};

HorizontalBarGraph.defaultProps = {
    data: { producedParts: 0, target: 0 }
};

export { HorizontalBarGraph };
