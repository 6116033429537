import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Icon } from "@circle/kip-components";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";

import styles from "./clusterProductTypes.module.scss";
import { InfiniteList } from "../../../generic/InfiniteList.jsx";
import { resolveClassNames } from "palstek";

const ClusterProductTypes = props => { // eslint-disable-line complexity
    const { trigger }   = useTrigger();
    const params        = useParams();
    const { translate } = useTranslation();
    const { sorting, options, metadata, isLoading, productTypes } = useBranch({
        sorting:      ["sortings", "products"],
        options:      ["queryOptions"],
        metadata:     ["metadata", "products"],
        isLoading:    ["loadingViews", "clusterProducts"],
        productTypes: ["sortedProducts"]
    });

    const onFetch = () => {
        trigger("fetchClusterProducts", { plantId: params.plantId, clusterId: params.clusterId, plantElementId: params.plantElementId, orderKey: sorting.property, order: sorting.isOrderAsc ? "asc" : "desc", reset: false });
    };

    const onSort = val => {
        const inter = sorting.isOrderAsc ? "desc" : "asc";
        const order = val !== sorting.property ? "desc" : inter;

        trigger("fetchClusterProducts", { plantId: params.plantId, clusterId: params.clusterId, plantElementId: params.plantElementId, orderKey: val, order, reset: true });
        trigger("setSorting", "products", val);
    };

    useEffect(() => {
        trigger("setSorting", "products", "portion");
        trigger("fetchClusterProducts", { plantId: params.plantId, clusterId: params.clusterId, plantElementId: params.plantElementId, orderKey: "portion", order: "desc", reset: true });
    }, []);

    useEffect(() => {
        trigger("fetchClusterProducts", { plantId: params.plantId, clusterId: params.clusterId, plantElementId: params.plantElementId, orderKey: sorting.property, order: sorting.isOrderAsc ? "asc" : "desc", reset: true });
    }, [options]);

    const isFullTable = params.plantElementId !== "null" && props.type === "FullCounterAndStates";

    return (
        <>
            {
                !isLoading &&
                <>
                    <p className={styles.header}>{ translate("overview.product.types") }</p>
                    <div className={resolveClassNames(styles[props.type], styles.dataTableContainer)}>
                        <div className={styles.headerContainer}>
                            <div className={styles.headerItem} onClick={() => onSort("name")}>
                                <span>{ (translate("name.headline")).toUpperCase() }</span>
                                {
                                    sorting.property === "name" &&
                                    <div className={styles.rowHeader}>
                                        <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                                    </div>
                                }
                            </div>
                            <div className={styles.headerItem} onClick={() => onSort("produced")}>
                                <span>{ (translate("dashboard.widgets.produced.header")).toUpperCase() }</span>
                                {
                                    sorting.property === "produced" &&
                                    <div className={styles.rowHeader}>
                                        <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                                    </div>
                                }
                            </div>
                            <div className={styles.headerItem} onClick={() => onSort("portion")}>
                                <span>{ (translate("overview.proportion")).toUpperCase() }</span>
                                {
                                    sorting.property === "portion" &&
                                    <div className={styles.rowHeader}>
                                        <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                                    </div>
                                }
                            </div>
                            {
                                isFullTable &&
                                <>
                                    <div className={styles.headerItem} onClick={() => onSort("processingTime")}>
                                        <span>{ (translate("detail.cluster.table.avg.prod.time")).toUpperCase() }</span>
                                        {
                                            sorting.property === "processingTime" &&
                                            <div className={styles.rowHeader}>
                                                <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                                            </div>
                                        }
                                    </div>
                                    <div className={styles.headerItem} onClick={() => onSort("processingTimeMax")}>
                                        <span>{ (translate("detail.cluster.table.max.prod.time")).toUpperCase() }</span>
                                        {
                                            sorting.property === "processingTimeMax" &&
                                            <div className={styles.rowHeader}>
                                                <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                                            </div>
                                        }
                                    </div>
                                    <div className={styles.headerItem} onClick={() => onSort("processingTimeMin")}>
                                        <span>{ (translate("detail.cluster.table.min.prod.time")).toUpperCase() }</span>
                                        {
                                            sorting.property === "processingTimeMin" &&
                                            <div className={styles.rowHeader}>
                                                <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                        {
                            (productTypes ?? []).length === 0 &&
                            <div>
                                <span>{ translate("datatable.empty") }</span>
                            </div>
                        }
                        <InfiniteList
                            className={styles.infinitelist}
                            metadata={metadata}
                            onFetch={onFetch}
                            element="div"
                            placeholder={<span></span>}
                            loader={() => <span>Loading...</span>}
                        >
                            {
                                (productTypes ?? []).map((x, idx) => (
                                    <div className={styles.rowContainer} key={idx}>
                                        <div className={styles.row}>
                                            <div className={styles.item}>
                                                <span className={styles.clamped}><b>{ translate(x.name) }</b></span>
                                            </div>
                                            <div className={styles.item}>
                                                <span className={styles.clamped}><b>{ translate(x.produced) }</b></span>
                                            </div>
                                            <div className={styles.item}>
                                                <span className={styles.clamped}>{x.portion}%</span>
                                            </div>
                                            {
                                                isFullTable &&
                                                <>
                                                    <div className={styles.item}>
                                                        <span className={styles.clamped}>{x?.processingTime?.avg > 0 ? `${x?.processingTime?.avg}s` : "-"}</span>
                                                    </div>
                                                    <div className={styles.item}>
                                                        <span className={styles.clamped}>{x?.processingTime?.max > 0 ? `${x?.processingTime?.max}s` : "-"}</span>
                                                    </div>
                                                    <div className={styles.item}>
                                                        <span className={styles.clamped}>{x?.processingTime?.min > 0 ? `${x?.processingTime?.min}s` : "-"}</span>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </InfiniteList>
                    </div>
                </>
            }
        </>
    );
};

ClusterProductTypes.propTypes = {
    type: PropTypes.string
};

export { ClusterProductTypes };
