import PropTypes from "prop-types";

const CircleCustomizedDot = props => {
    const { cx, cy } = props;

    if(props.value === null) return null;

    return (
        <svg x={cx && cx - 5} y={cy && cy - 5} width={12} height={12} viewBox="0 0 16 16" fill="#f5f5f5" stroke="#d7d7d7" strokeWidth="10">
            <circle cx="8" cy="8" r="4" stroke={props.color || "#d7d7d7"} strokeWidth="3" fill="#f5f5f5" />
        </svg>
    );
};

CircleCustomizedDot.propTypes = {
    cx:    PropTypes.number,
    cy:    PropTypes.number,
    value: PropTypes.number,
    color: PropTypes.string
};

export { CircleCustomizedDot };
