import { Button, Icon } from "@circle/kip-components";
import { useBranch, useTrigger, useTranslation } from "@circle/gestalt-app";

import { Breadcrumb } from "../../../generic/Breadcrumb/Breadcrumb";
import { RangeSelector } from "../../../generic/rangeSelector/RangeSelector";

import styles from "./clusterFavoriteViewHeader.module.scss";

const ClusterFavoriteViewHeader = () => {
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();
    const { options, cluster, isFavorite, plantElement } = useBranch({
        options:      ["queryOptions"],
        cluster:      ["selectedCluster"],
        plantElement: ["selectedPlantElement"],
        isFavorite:   ["isFavorite"]
    });

    const onObservationSelect = () => {
        if(plantElement) {
            const editFavorite =  {
                clusterId:      null,
                plantId:        plantElement.plantId,
                plantElementId: plantElement.id
            };

            trigger("editFavoriteElement", editFavorite);
            return;
        }
        const editFavorite =  {
            clusterId:      cluster.id,
            plantId:        cluster.plantId,
            plantElementId: null
        };

        trigger("editFavorite", editFavorite);
        return;
    };

    const onDatePick = date => {
        trigger("applyOption", "range", date.name);
        trigger("applyOption", "calendar", date);
    };

    return (
        <div className={styles.headerContainer}>
            <Breadcrumb homeLink="/favorite" items={[
                { value: translate(options.selectedPlantElement?.name ? options.selectedPlantElement?.name : options.selectedCluster?.name), path: `/performance/${options.selectedPlant?.id}/${options.cluster}`, index: 0 }
            ]} />

            <div className={styles.actionsContainer}>
                <Button _variant="icon" _appearance={isFavorite && "primary"} onClick={onObservationSelect} >
                    <Icon _icon="StarSolid" />
                </Button>
                <RangeSelector
                    isNested={true}
                    range={options.range}
                    selected={options.calendar}
                    onChange={onDatePick}
                />
            </div>
        </div>
    );
};

export { ClusterFavoriteViewHeader };
