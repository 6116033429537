import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "@circle/gestalt-app";
import { Button, TextInput } from "@circle/kip-components";
import styles from "./picker.module.scss";
import { unifyDateInput } from "../../../helper/date";
import { DateInput } from "../dateInput/DateInput";

const DateTimePicker = props => { // eslint-disable-line complexity, max-statements
    const { translate }     = useTranslation();
    const [showMinTimeLengthMessage, setShowMinTimeLengthMessage] = useState(false);
    const [value, setValue] = useState(props.value);
    const [disabledTime, setDisabledTime] = useState(props.hasToggleEnabled);

    const onChangeFrom = val => {
        const { until } = value;

        return setValue({ from: val, until });
    };

    const onChangeUntil = val => {
        const { from } = value;

        setValue({ from, until: val });
    };

    const onTimeFrom = e => {
        const { from, until } = value;
        const timeFrom = unifyDateInput(new Date(from).setHours(e.target.value.split(":")[0], e.target.value.split(":")[1]));

        setValue({ from: timeFrom, until });
    };

    const onTimeUntil = e => {
        const { from, until } = value;
        const timeUntil = unifyDateInput(new Date(until).setHours(e.target.value.split(":")[0], e.target.value.split(":")[1]));

        setValue({ from, until: timeUntil });
    };

    const onClose = e => {
        e.stopPropagation();

        setValue(props.value);

        props.onCancel();
    };

    const onSubmit = e => {
        e?.stopPropagation();

        if(!value.from || !value.until) return;

        props.onChange(value);
    };

    const onToggleTime = () => {
        if(!disabledTime) setValue({ from: new Date(new Date(value.from).setHours(0, 0, 0, 0)), until: new Date(new Date(value.until).setHours(23, 59, 59, 999)) });
        setDisabledTime(!disabledTime);
    };

    const isTimeRangeDefault = value.from.getHours() === 0 && value.from.getMinutes() === 0 && value.from.getSeconds() === 0 &&
    value.until.getHours() === 23 && value.until.getMinutes() === 59 && value.until.getSeconds() === 59;

    useEffect(() => {
        if(props.isModalView) return;

        onSubmit();
    }, [value]);

    useEffect(() => {
        if(isTimeRangeDefault) return;

        setDisabledTime(!disabledTime);
        return;
    }, []);

    const range = value.until - value.from;
    const resolveEnabledStatus = () => {
        const valid = (value.until && value.from && value.until > value.from);
        const minOneHour = range > 3599999;

        if(showMinTimeLengthMessage !== !minOneHour) setShowMinTimeLengthMessage(!minOneHour);
        return !(valid && minOneHour);
    };

    return (
        <>
            <div>
                <div className={styles.inputContainer}>
                    {
                        props.isModalView &&
                        <b>{ translate("calendar.from") }</b>
                    }
                    <div className={styles.inputBlock}>
                        <DateInput id="from" style={{ cursor: "pointer" }} locale={props.locale} date={props.value.from} onChange={onChangeFrom} />
                        <span className={styles.inputTime}>
                            <TextInput value={value?.from?.toLocaleTimeString().substring(0, 5)} onChange={onTimeFrom} isEnabled={!disabledTime} type="time" icon="Clock" />
                        </span>
                    </div>
                </div>
                <div className={styles.inputContainer}>
                    {
                        props.isModalView &&
                        <b>{ translate("calendar.to") }</b>
                    }
                    <div className={styles.inputBlock}>
                        <DateInput id="to" locale={props.locale} date={props.value.until} onChange={onChangeUntil}/>
                        <span className={styles.inputTime}>
                            <TextInput value={value?.until?.toLocaleTimeString().substring(0, 5)} onChange={onTimeUntil} isEnabled={!disabledTime} type="time" icon="Clock" />
                        </span>
                    </div>
                </div>
            </div>
            {
                props.hasToggleEnabled &&
                <label className={styles.timePickLabel}>
                    <input type="Checkbox" checked={!disabledTime} onChange={onToggleTime} className={styles.checkbox} />
                    { translate("calendar.setTime")}
                </label>
            }
            {showMinTimeLengthMessage &&
                <span className={styles.minTimeLenghtMessage}>
                    { translate("showMinTimeLengthMessage") }
                </span>
            }
            {
                props.isModalView &&
                <div className="flex mt-2 justify-end">
                    <Button onClick={onClose}>
                        { translate("actions.cancel") }
                    </Button>
                    <Button _appearance="primary" disabled={resolveEnabledStatus()} onClick={onSubmit}>
                        { translate("actions.select") }
                    </Button>
                </div>
            }
        </>
    );
};

DateTimePicker.propTypes = {
    value:            PropTypes.object,
    onCancel:         PropTypes.func,
    onChange:         PropTypes.func,
    locale:           PropTypes.string,
    isModalView:      PropTypes.bool,
    hasToggleEnabled: PropTypes.bool
};

DateTimePicker.defaultProps = {
    hasToggleEnabled: true,
    isModalView:      true
};

export { DateTimePicker };
