import PropTypes from "prop-types";

import { getRangeString } from "../../../helper/date";

import styles from "./plantOverviewHeader.module.scss";

const PlantOverviewHeader = props => {
    return (
        <div className={styles.plant}>
            { props.plant &&
            <div className={styles.logo}>
                <img src={props.plant?.manufacturer?.logo ?? "/images/Bitmap.png"}/>
            </div>
            }
            <div>
                <div className={styles.plantName}>
                    <span>{props.plant ? props.translate(props.plant?.name) : props.title }</span>
                    <span className={styles.slot}>{ props.options.range === "shift" ? props.translate("overview.selector.shift") :
                        getRangeString([props.options.calendar.from, props.options.calendar.until], props.currentLanguage)}</span>
                </div>
                <span className={styles.manufacturer}>{ props.plant?.manufacturer?.name }</span>
            </div>
        </div>
    );
};

PlantOverviewHeader.propTypes = {
    plant:           PropTypes.object,
    options:         PropTypes.object,
    translate:       PropTypes.func,
    currentLanguage: PropTypes.string,
    title:           PropTypes.string
};

export { PlantOverviewHeader };
