import PropTypes from "prop-types";

import { getRangeString } from "../../../helper/date";

import styles from "./clusterOverviewHeader.module.scss";

const ClusterOverviewHeader = props => {
    return (
        <div className={styles.cluster}>
            <div>
                <div className={styles.clusterName}>
                    <h6>{props.translate(props.cluster?.name)}</h6>
                    <h6 className={styles.slot}>{
                        getRangeString([props.options.calendar.from, props.options.calendar.until], props.currentLanguage)}</h6>
                </div>
            </div>
            <div className={styles.logoContainer}>
                <div className={styles.logo}>
                    <img src={props.cluster?.manufacturer?.logo ?? "/images/Bitmap.png"}/>
                </div>
                <span className={styles.manufacturer}>{ props.cluster?.manufacturer?.name }</span>
            </div>
        </div>
    );
};

ClusterOverviewHeader.propTypes = {
    cluster:         PropTypes.object,
    options:         PropTypes.object,
    translate:       PropTypes.func,
    currentLanguage: PropTypes.string
};

export { ClusterOverviewHeader };
