import { useTranslation } from "@circle/gestalt-app";
import { Icon } from "@circle/kip-components";
import { resolveClassNames } from "palstek";
import PropTypes from "prop-types";

import styles from "./sortableheader.module.scss";

const SortableHeader = props => {
    const { translate } = useTranslation();

    const onSort = (...x) => {
        if(!props.isVisible) return;

        props.onSort(...x);
    };

    return (
        <div className={resolveClassNames(styles.column, props.isVisible && styles.visible)} onClick={() => onSort(props.type)}>
            {
                props.isVisible &&
                <>
                    <span>{ translate(`${props.type}`) }</span>
                    {
                        props.sorting.isOrderAsc !== "hidden" && props.sorting.property === props.type &&
                        <Icon className={styles.icon} _icon={props.sorting.isOrderAsc ? "ArrowUp" : "ArrowDown" }/>
                    }
                </>
            }
        </div>
    );
};

SortableHeader.propTypes = {
    isVisible: PropTypes.bool,
    type:      PropTypes.string,
    onSort:    PropTypes.func,
    sorting:   PropTypes.shape({
        isOrderAsc: PropTypes.bool,
        property:   PropTypes.string
    })
};

export { SortableHeader };
