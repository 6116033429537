import { Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, Bar, ComposedChart } from "recharts";
import PropTypes from "prop-types";

import { SquareCustomizedDot } from "../../generic/squareCustomizedDot";
import { CircleCustomizedDot } from "../../generic/circleCustomizedDot/CircleCustomizedDot";
import { renderCustomizedLabel } from "../../../helper/customizeLabel";
import { Tooltip as CustomTooltip } from "../../generic/Tooltip";
import { BusyIndicator } from "../../BusyIndicator";

import styles from "./lineGraphWidget.module.scss";

const LineGraphWidget = props => { // eslint-disable-line complexity
    const isContentAvailable = props.data.length >= 1;

    return (
        <div className={styles.mainContainer} style={{ maxHeight: props.graphHeight ?? 250 }}>
            {
                props.isLoading && <>
                    <div className={styles.headerContainer}>
                        <span className={styles.headerTitle}><b>{props.mainHeaderTitle}</b>{" "}{props.secondaryHeaderTitle}</span>
                    </div>
                    <BusyIndicator />
                </>
            }
            {
                !props.isLoading && <>
                    <div className={styles.headerContainer}>
                        <span className={styles.headerTitle}><b>{props.mainHeaderTitle}</b>{" "}{props.secondaryHeaderTitle}</span>
                    </div>
                    <div className={styles.graphContainer}>
                        {
                            isContentAvailable &&
                            <ResponsiveContainer width="100%" height="100%">
                                <ComposedChart
                                    width={500}
                                    height={100}
                                    data={props.data}
                                    margin={{
                                        top:    25,
                                        right:  20,
                                        left:   -20,
                                        bottom: 30
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false}/>
                                    <XAxis dataKey={props.XAxisKey} tick={{ fontSize: 8 }} />
                                    <YAxis axisLine={{ stroke: "transparent" }} tick={{ fontSize: 8 }} />
                                    <Tooltip content={<CustomTooltip translate={props.translate} isNoUnit={props.isNoUnit} />}/>
                                    {props.squareLineDataKey &&
                                    <Line
                                        type="monotone"
                                        dataKey={props.squareLineDataKey}
                                        stroke={props.squareLineColor}
                                        strokeWidth={3}
                                        dot={false}
                                    />
                                    }
                                    {props.barDataKey &&
                                    <Bar dataKey={props.barDataKey} barSize={10} fill="#7DA7D9" >
                                        <LabelList dataKey={props.barDataKey} content={labelProps => renderCustomizedLabel({ ...labelProps, fontWeight: "normal", isLine: false, fontSize: 8 })} />
                                    </Bar>
                                    }
                                    { props.circleLineDataKey &&
                                    <Line
                                        type="monotone"
                                        dataKey={props.circleLineDataKey}
                                        stroke={props.circleLineColor}
                                        strokeWidth={3}
                                        dot={false}
                                    />
                                    }
                                </ComposedChart>
                            </ResponsiveContainer>
                        }
                        {
                            !isContentAvailable &&
                            <div className={styles.noContent}><span>{props.translate("overview.no.content")}</span></div>
                        }
                    </div>
                </>
            }
        </div>
    );
};

LineGraphWidget.propTypes = {
    circleLineDataKey:       PropTypes.string,
    squareLineDataKey:       PropTypes.string,
    legendCircleDescription: PropTypes.string,
    legendSquareDescription: PropTypes.string,
    mainHeaderTitle:         PropTypes.string,
    secondaryHeaderTitle:    PropTypes.string,
    graphHeight:             PropTypes.number,
    data:                    PropTypes.arrayOf(PropTypes.object),
    isLoading:               PropTypes.bool,
    translate:               PropTypes.func,
    XAxisKey:                PropTypes.string,
    squareLineColor:         PropTypes.string,
    circleLineColor:         PropTypes.string,
    isNoUnit:                PropTypes.bool,
    barDataKey:              PropTypes.string
};

LineGraphWidget.defaultProps = {
    isLoading:       false,
    XAxisKey:        "date",
    squareLineColor: "#7d7d7d",
    circleLineColor: "#d7d7d7"

};

export { LineGraphWidget };
