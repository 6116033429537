import PropTypes from "prop-types";
import { useTranslation } from "@circle/gestalt-app";

import { LineGraphWidget } from "../../../widgets/lineGraphWidget";
import { ProductivityCard } from "./productivityCard";
import { EventsTableCard } from "./eventsTableCard";

import styles from "./clusterStatisticsContent.module.scss";
import { AvailabilityGraph } from "./availabilityGraph/AvailabilityGraph";

const ClusterStatisticsContent = props => { // eslint-disable-line complexity
    const { translate } = useTranslation();

    return (
        <div className={styles.contentContainer}>
            {
                props.type.includes("States") &&
                <AvailabilityGraph
                    isLoading={props.isLoading}
                    data={props.availability ?? []}
                />
            }
            {
                props.type.includes("Counter") &&
                <ProductivityCard runtimeCheck={props.runtimeCheck} data={props.performance ?? {}} isLoading={props.isLoading} />
            }
            {
                props.type.includes("Counter") &&
                <LineGraphWidget
                    legendCircleDescription={`ø${translate("title.cycle.time")}`}
                    mainHeaderTitle={translate("detail.cluster.table.production.time")}
                    circleLineDataKey={"cycleTime"}
                    graphHeight={200}
                    data={props.performanceGraph ?? []}
                    translate={translate}
                    isLoading={props.isLoading}
                />
            }
            <EventsTableCard type={props.type}/>
        </div>
    );
};

ClusterStatisticsContent.propTypes = {
    isFavorite:       PropTypes.bool,
    performance:      PropTypes.object,
    availability:     PropTypes.arrayOf(PropTypes.object),
    performanceGraph: PropTypes.arrayOf(PropTypes.object),
    eventsList:       PropTypes.arrayOf(PropTypes.object),
    isLoading:        PropTypes.bool,
    type:             PropTypes.string,
    runtimeCheck:     PropTypes.bool
};

ClusterStatisticsContent.defaultProps = {
    type: ""
};

export { ClusterStatisticsContent };
