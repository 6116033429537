import PropTypes from "prop-types";
import { resolveClassNames } from "palstek";

import styles from "./adminModal.module.scss";


const AdminModal = props => {
    return (
        <div className={resolveClassNames(styles.modal, props.isOpen && styles.open)}>
            <div className={styles.modalContainer}>
                <div className={styles.modalHeader}>
                    <h6>{props.title}</h6>
                </div>
                {props.children}
            </div>
        </div>
    );
};

AdminModal.propTypes = {
    isOpen:   PropTypes.bool,
    children: PropTypes.node,
    title:    PropTypes.string
};

export { AdminModal };
