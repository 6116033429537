import PropTypes from "prop-types";
import { resolveClassNames } from "palstek";

import styles from "./progressbar.module.scss";

const ProgressBar = props => {
    return (
        <div className={resolveClassNames((props.isHeightSmall ? styles.smallProgress : styles.progress), props.className)}>
            <div className={props.isHeightSmall ? styles.smallBar : styles.bar} style={{ width: `${props.value}%` }}/>
        </div>
    );
};

ProgressBar.propTypes = {
    className:     PropTypes.string,
    value:         PropTypes.number,
    isHeightSmall: PropTypes.bool
};

export { ProgressBar };
